import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  ThemeProvider,
  createTheme,
  Container,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  TextField,
  ListItemIcon,
  Button,
  IconButton,
  styled,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { format, parseISO } from "date-fns";
import {
  TrendingUp,
  TrendingDown,
  Info,
  HelpOutline,
} from "@mui/icons-material";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import DialogueBox from "../DialogueBox";
import InsightCard from "../InsightCard";
// import SankeyDiagram from "../SankeyDiagram";
import PipelineChart from "../PipelineChart";
import AgentScorecard from "../AgentScorecard";

import * as d3 from "d3";
import { sankey, sankeyLinkHorizontal } from "d3-sankey";
const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  fontSize: "1.1rem",
  minWidth: "180px",
}));

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";

const SankeyDiagram = ({ stageLogs, dailyLogs }) => {
  const svgRef = useRef(null);
  const [error, setError] = useState("");
  const [stageMap, setStageMap] = useState({});
  const [loading, setLoading] = useState({});

  useEffect(() => {
    const fetchStageNames = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const stagesResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/stages`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const map = stagesResponse.data.reduce((acc, stage) => {
          acc[stage.id] = stage.name;
          return acc;
        }, {});

        setStageMap(map);
      } catch (error) {
        setLoading(false);

        console.error("Error fetching stage names:", error);
      }
    };

    fetchStageNames();
  }, []);

  useEffect(() => {
    if (
      !stageLogs ||
      !dailyLogs ||
      stageLogs.length === 0 ||
      dailyLogs.length === 0 ||
      Object.keys(stageMap).length === 0
    ) {
      setError("No logs provided for Sankey diagram or stage map is empty");
      return;
    }

    const nodes = [];
    const links = [];
    const stageNames = new Set();

    // Add agent nodes (representing their SLZ)
    dailyLogs.forEach((agentLog) => {
      const latestLog = agentLog.logs[agentLog.logs.length - 1];
      const slzCount = latestLog?.smartListCounts["Smart List Zero"] || 0;
      nodes.push({ name: `${agentLog.agentName} SLZ`, value: slzCount });
    });

    // Collect all stage names from stageLogs and stageMap
    stageLogs.forEach((agentLog) => {
      agentLog.logs.forEach((log) => {
        if (log && log.stageCounts) {
          Object.keys(log.stageCounts).forEach((stageId) => {
            const stageName = stageMap[stageId] || `Unknown Stage (${stageId})`;
            if (!stageNames.has(stageName)) {
              stageNames.add(stageName);
              nodes.push({ name: stageName });
            }
          });
        }
      });
    });

    // Create links from SLZ to stages
    stageLogs.forEach((agentLog) => {
      const latestStageLog = agentLog.logs[agentLog.logs.length - 1];
      const latestDailyLog = dailyLogs.find(
        (dl) => dl.agentName === agentLog.agentName
      )?.logs[0];
      const slzCount = latestDailyLog?.smartListCounts["Smart List Zero"] || 0;

      if (latestStageLog && latestStageLog.stageCounts) {
        Object.entries(latestStageLog.stageCounts).forEach(
          ([stageId, count]) => {
            const stageName = stageMap[stageId] || `Unknown Stage (${stageId})`;
            const sourceIndex = nodes.findIndex(
              (n) => n.name === `${agentLog.agentName} SLZ`
            );
            const targetIndex = nodes.findIndex((n) => n.name === stageName);

            if (sourceIndex === -1) {
              console.error(
                `Source node not found for agent: ${agentLog.agentName}`
              );
              return;
            }
            if (targetIndex === -1) {
              console.error(`Target node not found for stage: ${stageName}`);
              return;
            }

            links.push({
              source: sourceIndex,
              target: targetIndex,
              value: count,
            });
          }
        );
      }
    });

    if (nodes.length === 0 || links.length === 0) {
      console.error("No valid nodes or links created for Sankey diagram");
      return;
    }

    const data = { nodes, links };

    const svg = d3.select(svgRef.current);
    const { width, height } = svg.node().getBoundingClientRect();

    try {
      const sankeyGenerator = sankey()
        .nodeWidth(15)
        .nodePadding(10)
        .extent([
          [1, 1],
          [width - 1, height - 6],
        ]);

      const { nodes: sankeyNodes, links: sankeyLinks } = sankeyGenerator(data);

      svg.selectAll("*").remove();

      // Color scale for nodes
      const color = d3.scaleOrdinal(d3.schemeCategory10);

      // Nodes
      svg
        .append("g")
        .selectAll("rect")
        .data(sankeyNodes)
        .join("rect")
        .attr("x", (d) => d.x0)
        .attr("y", (d) => d.y0)
        .attr("height", (d) => d.y1 - d.y0)
        .attr("width", (d) => d.x1 - d.x0)
        .attr("fill", (d) => color(d.name.split(" ")[0])) // Color by agent name or stage
        .append("title")
        .text((d) => `${d.name}\nTotal: ${d.value}`);

      // Links
      svg
        .append("g")
        .attr("fill", "none")
        .selectAll("path")
        .data(sankeyLinks)
        .join("path")
        .attr("d", sankeyLinkHorizontal())
        .attr("stroke", (d) =>
          d3.color(color(d.source.name.split(" ")[0])).darker(0.5)
        )
        .attr("stroke-width", (d) => Math.max(1, d.width))
        .attr("opacity", 0.5)
        .append("title")
        .text((d) => `${d.source.name} → ${d.target.name}\nCount: ${d.value}`);

      // Labels
      svg
        .append("g")
        .style("font", "10px sans-serif")
        .selectAll("text")
        .data(sankeyNodes)
        .join("text")
        .attr("x", (d) => (d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6))
        .attr("y", (d) => (d.y1 + d.y0) / 2)
        .attr("dy", "0.35em")
        .attr("text-anchor", (d) => (d.x0 < width / 2 ? "start" : "end"))
        .text((d) => d.name)
        .call((text) =>
          text
            .filter((d) => d.x0 < width / 2)
            .attr("x", (d) => d.x1 + 6)
            .attr("text-anchor", "start")
        )
        .call((text) =>
          text
            .filter((d) => d.x0 >= width / 2)
            .attr("x", (d) => d.x0 - 6)
            .attr("text-anchor", "end")
        );
    } catch (error) {
      console.error("Error generating Sankey diagram:", error);
      return;
    }
  }, [stageLogs, dailyLogs, stageMap]);

  return <svg ref={svgRef} width="100%" height="600px"></svg>;
};

const theme = createTheme({
  palette: {
    primary: { main: "#3a86ff" },
    secondary: { main: "#ff006e" },
    success: { main: "#38b000" },
    warning: { main: "#ffbe0b" },
    error: { main: "#d90429" },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h3: { fontWeight: 700, letterSpacing: "-0.5px" },
    h4: { fontWeight: 600, letterSpacing: "-0.5px" },
    h5: { fontWeight: 600 },
    h6: { fontWeight: 600 },
    body1: { fontSize: "1rem", lineHeight: 1.7 },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          boxShadow: "0 4px 20px 0 rgba(0,0,0,0.1)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
  },
});
const StyledContainer = styled(Container)(({ theme }) => ({
  maxWidth: "1400px !important",
  padding: theme.spacing(4),
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiInputBase-root": {
    fontSize: "1.1rem",
    padding: theme.spacing(1),
  },
}));

const LeadInsights = () => {
  const [insights, setInsights] = useState(null);
  const [agentData, setAgentData] = useState([]);
  const [dailyLogs, setDailyLogs] = useState([]);
  const [stageLogs, setStageLogs] = useState([]);
  const [selectedStages, setSelectedStages] = useState([]);
  const [stages, setStages] = useState([]);
  const [stageMap, setStageMap] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [showFUBKEYDialog, setShowFUBKeyDialog] = useState(false);
  const [fetchDate, setFetchDate] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [savedStages, setSavedStages] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    marginTop: "2rem",
    height: "50px",
    backgroundColor: isHovered ? "rgb(1, 57, 52)" : "#02524B",
    color: "white", // Adjust as needed
    transition: "background-color 0.3s",
    cursor: "pointer",
  };

  const userId = user?._id;

  const fetchSelections = useCallback(async (stageOptions) => {
    try {
      // const token = localStorage.getItem("token");
      const token = Cookies.get("token");
      const response = await axios.get(`${API_BASE_URL}/api/get-selections`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);

      if (
        (user.subscriptionStatus === "active" &&
          user.fubApiKey &&
          !response.data) ||
        (response.data.agentIds.length === 0 &&
          response.data.smartListIds.length === 0)
      ) {
        setError("Please select metrics to review insights");
        return;
      }

      const selections = response.data || {};
      const selectedStageOptions = (selections.stageIds || [])
        .map((id) =>
          stageOptions.find((stage) => stage.value.toString() === id.toString())
        )
        .filter(Boolean);

      setSelectedStages(selectedStageOptions);
    } catch (error) {
      console.error("Error fetching selections:", error);
      setErrorMessage("Error fetching selections. Please try again.");
    }
  }, []);

  // fetchStages
  const fetchStages = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(`${API_BASE_URL}/api/stages`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedStages = response.data.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      return sortedStages.map((stage) => ({
        value: stage.id,
        label: stage.name,
      }));
    } catch (error) {
      console.error("Error fetching stages:", error);
      // setErrorMessage("Error fetching stages. Please try again.");
      return [];
    }
  }, []);

  // Fetch Data

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const stageOptions = await fetchStages();

      setStages(stageOptions);

      await fetchSelections(stageOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setErrorMessage("Error fetching data. Please try again.");
    }
  }, [fetchStages, fetchSelections]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // saveSelections

  const handleSelection = async (selections) => {
    // setIsGettingCounts(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${API_BASE_URL}/api/selected-counts`,
        {
          agentIds: selections.agentIds,
          smartListIds: selections.smartListIds,
          stageIds: selections.stageIds,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const responseData = response.data;
      if (userId) {
        localStorage.setItem(`counts-${userId}`, JSON.stringify(responseData));
      }

      // Update counts state with the fetched data
      // setCounts(responseData);
      if (responseData.date) {
        const parsedDate = parseISO(responseData.date);
        const formattedDate = isNaN(parsedDate)
          ? "Invalid Date"
          : format(parsedDate, "dd/MM/yyyy");
        setFetchDate(formattedDate);
      }
    } catch (error) {
      console.error("Error fetching selected counts:", error);
      // setErrorMessage("Error fetching selected counts. Please try again.");
      setFetchDate("Invalid Date");
    }
  };

  const saveSelections = async (stageIds) => {
    if (userId) {
      // Remove item from localStorage
      localStorage.removeItem(`stages-${userId}`);
    }

    try {
      const token = Cookies.get("token");
      const response = await axios.post(
        `${API_BASE_URL}/api/get-stages`,
        { stageIds },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success) {
        if (userId) {
          const stageIds = response.data.selections.stageIds;
          setSavedStages(stageIds);
          localStorage.setItem(`stages-${userId}`, JSON.stringify(savedStages));
        }

        return response.data.selections;
      }
      // await handleSelection();
    } catch (error) {
      console.error("Error saving selections:", error);
      // setError("Failed to save selections. Please try again.");
    }
  };

  const handleShowInsights = async (btnType) => {
    console.log(btnType);
    // setLoading(true); // Set loading state to true for insights fetching
    if (btnType === 2) {
      setIsSaving(true); // Set saving state to true at the start
    }
    if (btnType === 1) {
      setIsUpdating(true);
    }

    const stageIds = selectedStages.map((stage) => stage.value);

    try {
      const selections = await saveSelections(stageIds);
      if (selections) {
        await handleSelection(selections);
      }

      // Save selected stages
      await fetchInsights();
    } catch (error) {
      console.error("Error in handleShowInsights:", error);
    } finally {
      setIsSaving(false);
      setLoading(false); // Set loading state to true for insights fetching
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    if (savedStages.length > 0 && userId) {
      localStorage.setItem(`stages-${userId}`, JSON.stringify(savedStages));
    }
  }, [savedStages, userId]);
  useEffect(() => {
    const savedStagesString = localStorage.getItem(`stages-${userId}`);

    // If there is data in localStorage
    if (savedStagesString) {
      try {
        const persistedSavedStages = JSON.parse(savedStagesString);
        // Check if it's an array before setting state
        if (Array.isArray(persistedSavedStages)) {
          setSavedStages(persistedSavedStages);
        } else {
          console.error("Expected an array but got:", persistedSavedStages);
        }
      } catch (error) {
        console.error("Error parsing saved stages:", error);
      }
    }
  }, [userId]);

  // User plus fubAPiKey =========================================
  useEffect(() => {
    if (user && user.subscriptionStatus === "active" && !user.fubApiKey) {
      console.log(user, user.subscriptionStatus, user?.fubApiKey);
      console.log(showFUBKEYDialog);
      setShowFUBKeyDialog(true);
    }
  }, [user]);

  const calculatePerformanceScore = (agent) => {
    if (!agent) return 0;
    const maxScore = 100;
    const dailyWeight = 0.6;
    const stageWeight = 0.4;

    const dailyScore = Math.max(0, 100 - (agent.avgDailyTotal || 0));
    const stageScore = Math.max(0, 100 - (agent.avgStageTotal || 0));

    const weightedScore = dailyScore * dailyWeight + stageScore * stageWeight;

    return Math.min(Math.max(weightedScore, 0), 100);
  };

  const fetchInsights = async () => {
    if (!user) {
      setError("User not authenticated");
      setLoading(true);
      return;
    }

    try {
      // setLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}/api/lead-insights`,
        {}
      );

      setInsights(response.data.insights || null);

      const updatedAgentData = (response.data.agentData || []).map((agent) => ({
        ...agent,
        performanceScore: calculatePerformanceScore(agent),
      }));
      setAgentData(updatedAgentData);

      setDailyLogs(response.data.dailyLogs || []);

      setStageLogs(response.data.stageLogs || []);

      setStageMap(response.data.stageMap || {});
    } catch (error) {
      console.error("Error fetching lead insights:", error);
      // setError("Failed to fetch lead insights. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInsights(); // Wait for insights to be fetched
  }, []);

  if (user && user.subscriptionStatus === "active" && user.fubApiKey && error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6" color="error" gutterBottom>
          {error}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
          sx={{ marginTop: "20px" }}
        >
          Back to Select Metrics
        </Button>
      </Box>
    );
  }

  return (
    <>
      {showFUBKEYDialog && <DialogueBox />}

      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          position="absolute"
          top="50%" /* Adjust this value for positioning */
          left="50%"
          sx={{
            transform: "translate(-50%, -50%)",
          }}
        >
           <CircularProgress sx={{ color: '#02524B' }} />
        </Box>
      )}

      {savedStages.length === 0 && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          width="100%"
          height="100vh"
          sx={{ marginTop: "280px" }}
        >
          <Typography variant="h6" gutterBottom>
            Please select the stages to review Lead Insights.
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={3}
            sx={{ flexGrow: 1 }}
          >
            <StyledAutocomplete
              multiple
              options={stages}
              value={selectedStages}
              onChange={(event, value) => setSelectedStages(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select stages"
                  variant="outlined"
                  fullWidth
                  sx={{ width: "550px" }}
                />
              )}
            />
            <StyledButton
              disabled={isUpdating || selectedStages.length === 0}
              variant="contained"
              onClick={() => {
                handleShowInsights(1);
              }}
              fullWidth
              sx={{ height: "56px", width: "550px" }}
            >
              {isUpdating ? <CircularProgress /> : "Show Insights"}
            </StyledButton>
          </Box>
        </Box>
      )}

      <ThemeProvider theme={theme}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            position="absolute"
            top="50%" /* Adjust this value for positioning */
            left="50%"
            sx={{
              transform: "translate(-50%, -50%)",
            }}
          >
             <CircularProgress sx={{ color: '#02524B' }} />
          </Box>
        ) : (
          savedStages.length > 0 &&
          insights && (
            <Container maxWidth="xl">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={4}
                mt={3}
              >
                <Typography variant="h3" component="h1">
                  Real Estate Performance Hub
                </Typography>

                {/* <Box sx={{ flexGrow: 1, mt: 4 }}> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "40px",
                  }}
                >
                  <StyledContainer>
                    <Box>
                      <Typography variant="h6" component="h2" gutterBottom>
                        Stages
                      </Typography>
                      <StyledAutocomplete
                        multiple
                        options={stages}
                        value={selectedStages}
                        onChange={(event, value) => setSelectedStages(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select stages"
                            variant="outlined"
                            fullWidth
                            sx={{ width: "550px" }}
                          />
                        )}
                      />
                    </Box>
                    <Box sx={{ marginTop: "10" }}>
                      <Button
                        disabled={isSaving || selectedStages.length === 0}
                        variant="contained"
                        // color="primary"
                        onClick={() => {
                          // This is a button which shows in top right corner show insights page and user can refresh the record after clicking on it
                          handleShowInsights(2);
                        }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={buttonStyle}
                        fullWidth
                        sx={{ height: "56px", width: "550px" }}
                      >
                        {isSaving ? <CircularProgress /> : "Show Insights"}
                        {/* Show Insights */}
                      </Button>
                    </Box>
                  </StyledContainer>
                  {/* <Grid spacing={2}>
                        <Grid item xs={8}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4}>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid> */}
                </Box>
                {/* <Box>
                      <Button
                        startIcon={<Refresh />}
                        onClick={handleRefresh}
                        disabled={isRefreshing}
                        sx={{ mr: 2 }}
                      >
                        {isRefreshing ? "Refreshing..." : "Refresh Data"}
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleLogout}
                      >
                        Log Out
                      </Button>
                    </Box> */}
              </Box>
              {savedStages.length > 0 && insights && (
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <InsightCard
                      title="Team Performance Overview"
                      content={insights?.teamPerformance || "No data available"}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" gutterBottom>
                          Top Performers
                        </Typography>
                        <List>
                          {insights?.topPerformers &&
                            insights.topPerformers.map((performer, index) => (
                              <ListItem key={index}>
                                <ListItemIcon>
                                  <TrendingUp color="success" />
                                </ListItemIcon>
                                <ListItemText
                                  primary={performer.name}
                                  secondary={performer.reason}
                                />
                              </ListItem>
                            ))}
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" gutterBottom>
                          Needs Improvement
                        </Typography>
                        <List>
                          {insights?.needsImprovement &&
                            insights.needsImprovement.map((agent, index) => (
                              <ListItem key={index}>
                                <ListItemIcon>
                                  <TrendingDown color="error" />
                                </ListItemIcon>
                                <ListItemText
                                  primary={agent.name}
                                  secondary={agent.advice}
                                />
                              </ListItem>
                            ))}
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                  {/* {Object.keys(stageLogs).length > 0 && ( */}
                  <>
                    <Grid item xs={12}>
                      <SankeyDiagram
                        stageLogs={stageLogs}
                        dailyLogs={dailyLogs}
                        stageMap={stageMap}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PipelineChart stageLogs={stageLogs} />
                    </Grid>
                  </>
                  {/* )} */}

                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      Agent Scorecards
                      <Tooltip title="These scorecards provide a detailed view of each agent's performance metrics and trends.">
                        <IconButton size="small">
                          <HelpOutline fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    {agentData.map((agent) => (
                      <AgentScorecard key={agent.name} agent={agent} />
                    ))}
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" gutterBottom>
                          General Tips for Improvement
                          <Tooltip title="These tips are AI-generated suggestions to help improve overall team performance.">
                            <IconButton size="small">
                              <HelpOutline fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                        <List>
                          {insights?.generalTips &&
                            insights.generalTips.map((tip, index) => (
                              <ListItem key={index}>
                                <ListItemIcon>
                                  <Info color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={tip} />
                              </ListItem>
                            ))}
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
            </Container>
          )
        )}
      </ThemeProvider>
    </>
  );
};

export default LeadInsights;
