import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Typography, Box, Alert } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import AuthLayout from "./AuthLayout";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  String(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
);

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { register } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear any previous errors
    setSuccessMessage(""); // Clear any previous success messages

    try {
      const response = await register(email, password, promoCode);

      if (response.redirectToDashboard) {
        // Promo code valid and user registered
        setSuccessMessage(response.message); // Display success message
        setTimeout(() => navigate("/login"), 3000); // Redirect to login
      } else if (response.sessionId) {
        if (response.message) {
          // Invalid promo code with session ID
          setError(response.message); // Display error message
          setTimeout(async () => {
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({
              sessionId: response.sessionId,
            });

            if (error) {
              setError(error.message); // Show Stripe error if any
            }
          }, 3000); // Wait for 3 seconds before redirecting
        }
      }
    } catch (err) {
      // Handle any registration errors
      setError(err.message);
    }
  };

  return (
    <AuthLayout title="Register">
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          margin="normal"
          fullWidth
          name="promoCode"
          label="Promo Code (Optional)"
          id="promoCode"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Register
        </Button>
        {error && <Alert severity="error">{error}</Alert>}
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
      </Box>
    </AuthLayout>
  );
};

export default Register;
