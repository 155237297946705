import React from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
} from "@mui/material";

const ShowStepper = ({ leadDetails }) => {
  const recentHistory = leadDetails?.recentHistory || [];

  // Function to format the UTC date and time to a US time zone
  const formatDate = (date) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: 'America/New_York', // Change to preferred USA timezone
      timeZoneName: 'short'
    };
    return new Date(date).toLocaleString('en-US', options);
  };

  return (
    <Box>
      <Stepper orientation="vertical" activeStep={-1}>
        {recentHistory.map((historyItem) => (
          <Step key={historyItem._id}>
            <StepLabel>
              {historyItem.claimType === "ClaimedFromPond" &&
              historyItem.previousAssignment.assignmentType === "pond" &&
              historyItem.newAssignment.assignmentType === "agent"
                ? `Claimed by Lee Adkins from ${historyItem?.previousAssignment?.pondName} and assigned to ${historyItem.newAssignment.assignedToName}`
                : historyItem.claimType === "ReleasedToPond" &&
                  historyItem.previousAssignment.assignmentType === "pond" &&
                  historyItem.newAssignment.assignmentType === "pond"
                ? `Released from ${historyItem.previousAssignment?.pondName} by Lee Adkins to ${historyItem.newAssignment?.pondName}`
                : historyItem.claimType === "ReleasedToPond" &&
                  historyItem.previousAssignment.assignmentType === "agent" &&
                  historyItem.newAssignment.assignmentType === "pond"
                ? `Released to ${historyItem.newAssignment?.pondName} by Lee Adkins`
                : historyItem.claimType === "FTC"
                ? `Assigned to ${historyItem.newAssignment.assignedToName} by Lee Adkins`
                : `Assigned to ${historyItem.newAssignment.assignedToName} by Lee Adkins`}
              {/* Displaying the Date and Time */}
              <Typography variant="body2" color="textSecondary">
                {`On: ${formatDate(historyItem.claimedAt)}`}
              </Typography>
            </StepLabel>

            <StepContent>
              <Typography variant="body2">
                {`Stage: ${historyItem.leadStage}`}
              </Typography>
              <Typography variant="body2">
                {`Claimed At: ${formatDate(historyItem.claimedAt)}`}
              </Typography>
              <Typography variant="body2">
                {`Previous Assignment: ${historyItem.previousAssignment.assignedToName}`}
              </Typography>
              <Typography variant="body2">
                {`New Assignment: ${historyItem.newAssignment.assignedToName}`}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ShowStepper;
